import type {
  getLandingPageData,
  getTestimonialsSection,
} from "app/server/strapi.server";
import { HomeArticle } from "components/Homepage/HomeArticle.tsx";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { OptimizedImage } from "components/OptimizedImage";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes";
import { ArrowLeft, ArrowRight } from "iconoir-react";
import { useState } from "react";
import Carousel from "react-simply-carousel";
export const TestimonialsSection = ({
  testimonials,
  title,
}: {
  testimonials: Awaited<
    ReturnType<typeof getLandingPageData>
  >["testimonialSection"]["testimonials"];
  title: string;
}) => {
  const [activeSlide, setActiveSlide] = useState(1);
  return (
    <HomeSection
      color="gray100"
      verticalPadding={"clint"}
      horizontalPadding={"none"}
    >
      <HomeArticle verticalPadding={"none"} horizontalPadding={"none"}>
        <div className="flex flex-col items-center gap-6 lg:gap-12">
          <Headline size={"h2"} className="px-5 lg:px-10">
            {title}
          </Headline>
          <Carousel
            containerProps={{
              style: {
                width: "100%",
                justifyContent: "space-between",
                // userSelect: "none"
              },
            }}
            preventScrollOnSwipe
            // autoplay
            autoplayDelay={10000}
            swipeTreshold={60}
            activeSlideIndex={activeSlide}
            infinite
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: (
                <button
                  aria-label="Avis suivant"
                  className="bg-midnightblue rounded-clint absolute right-0 top-0 z-20 mr-2 translate-y-full items-center justify-center p-2 text-lg transition-colors duration-100 lg:mr-10 lg:flex lg:translate-y-0 lg:px-4 lg:py-3"
                >
                  <ArrowRight
                    className="size-4 text-white lg:size-8"
                    strokeWidth={2}
                  />
                </button>
              ),
            }}
            backwardBtnProps={{
              children: (
                <button
                  aria-label="Avis précédent"
                  className="bg-midnightblue rounded-clint absolute right-2 top-0 z-20 mr-2 -translate-x-full translate-y-full items-center justify-center p-2 text-lg transition-colors duration-100 lg:mr-10 lg:flex lg:translate-y-0 lg:px-4 lg:py-3"
                >
                  <ArrowLeft
                    className="size-4 text-white lg:size-8"
                    strokeWidth={2}
                  />
                </button>
              ),
            }}
            itemsToShow={2}
            speed={750}
            centerMode={true}
          >
            {testimonials.map((testimonial) => (
              <TestimonialItem
                key={testimonial.name}
                testimonial={testimonial}
              />
            ))}
          </Carousel>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

type TestimonialType = Awaited<
  ReturnType<typeof getTestimonialsSection>
>["testimonials"][number];
const TestimonialItem = ({ testimonial }: { testimonial: TestimonialType }) => {
  const { isLargeScreen } = useTailwindScreenSizes({});
  return (
    <div className="px-2">
      <div className="rounded-clint flex flex-wrap h-full max-h-[468px] w-[60vw] grow items-stretch gap-4 lg:gap-8 overflow-y-auto bg-white p-6">
        {testimonial?.company_logo ? (
          <OptimizedImage
            maxHeight={isLargeScreen ? 420 : 420 / 2}
            source={testimonial.company_logo.url}
            alt={testimonial.company_logo.alternativeText}
            width={testimonial.company_logo.width}
            height={testimonial.company_logo.height}
            containerClassName="lg:mx-0 self-center lg:basis-[325px]"
            // loading="lazy"
            imageProps={{
              minHeight: "auto",
              objectFit: "contain",
            }}
          />
        ) : null}
        <div className="flex flex-col gap-4 lg:basis-[535px] grow">
          <Paragraph
            size="lg"
            className="block max-w-[70ch] overflow-y-auto text-gray-600"
          >
            {testimonial.testimonial}
          </Paragraph>
          <div className="lg:mt-auto flex flex-row items-center gap-4">
            {testimonial?.photo ? (
              <div>
                <OptimizedImage
                  maxWidth={300}
                  source={testimonial.photo.url}
                  alt={testimonial.photo.alternativeText}
                  width={testimonial.photo.width}
                  height={testimonial.photo.height}
                />
              </div>
            ) : null}
            <div className="flex flex-col">
              <Label size="M">{testimonial?.name}</Label>
              {testimonial?.role ? (
                <Label size="S" className="text-gray-600">
                  {testimonial.role}
                </Label>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
